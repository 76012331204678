.object-other {
  background-position: 50%;
  background-size: cover;
  width: 402px;
  height: 402px;
  position: relative;
}

.saly {
  width: 96px;
  height: 96px;
  position: relative;
}

.saly-wrapper {
  background-position: 50%;
  background-size: cover;
  width: 443px;
  height: 444px;
  position: relative;
}

.index {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.index .navigation-left {
  background-color: #7086ff;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 38px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.index .logo {
  object-fit: cover;
  width: 90px;
  height: 19px;
  position: relative;
}

.index .navigation-right {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 18px;
  height: 32px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.index .text-wrapper {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.index .main-cta {
  width: 78px;
  height: 23.25px;
  transition: all .2s;
  position: relative;
}

.index .overlap-group {
  width: 76px;
  height: 23px;
  transition: transform .3s;
  position: relative;
}

.index .overlap-group:hover, .index .overlap-group:active {
  transform: scale(1.1);
}

.index .rectangle {
  background-color: #fff;
  border-radius: 5px;
  width: 76px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 0;
}

.index .div {
  color: #7086ff;
  letter-spacing: 0;
  text-align: center;
  width: 76px;
  height: 23px;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 5px;
  left: 0;
}

.index .page {
  background-color: #fff;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 692px;
  padding: 44px 0 0;
  display: flex;
  position: relative;
}

.index .frame {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.index .frame-2 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 11px;
  width: 100%;
  padding: 0 30px;
  display: flex;
  position: relative;
}

.index .p {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 29px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-frame {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 0 21px;
  display: flex;
  position: relative;
}

.index .deploy-any-backend {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  flex: 1;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .span {
  color: #000;
  letter-spacing: 0;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 18px;
  font-weight: 700;
}

.index .text-wrapper-2 {
  text-decoration: underline;
}

.index .cta {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 25px 0 42px;
  display: inline-flex;
  position: relative;
}

.index .explained-cta {
  width: 221px;
  height: 35px;
  margin-right: -2px;
  transition: all .2s;
  position: relative;
}

.index .explained-cta:hover {
  transform: scale(1.1);
}

.index .explain-your-cta-wrapper {
  background-color: #7086ff;
  border-radius: 5px;
  width: 219px;
  height: 35px;
  position: relative;
}

.index .explain-your-cta {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 189px;
  height: 19px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 7px;
  left: 16px;
}

.index .text-wrapper-3 {
  color: #656565;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .group-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 407px;
  height: 256px;
  margin-left: -39.5px;
  margin-right: -39.5px;
  padding: 0 30px 2px;
  display: flex;
  position: relative;
}

.index .group {
  object-fit: cover;
  width: 226px;
  height: 204px;
  position: relative;
}

.index .frame-3 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 129px;
  display: flex;
  position: relative;
}

.index .banner {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.index .using-your {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-4 {
  color: #000;
}

.index .text-wrapper-5 {
  color: #ffaef2;
  font-family: Product Sans-Bold, Helvetica;
  font-weight: 700;
}

.index .banner-2 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 55px;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.index .r {
  width: 34px;
  height: 14px;
  position: relative;
}

.index .asap-net-logo {
  object-fit: cover;
  width: 33px;
  height: 14px;
  position: relative;
}

.index .img {
  object-fit: cover;
  width: 44px;
  height: 14px;
  position: relative;
}

.index .element-flask {
  object-fit: cover;
  width: 41px;
  height: 14px;
  position: relative;
}

.index .OIP {
  object-fit: cover;
  width: 70px;
  height: 19px;
  position: relative;
}

.index .img-2 {
  object-fit: cover;
  width: 34px;
  height: 17px;
  position: relative;
}

.index .banner-3 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 56px;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.index .img-3 {
  object-fit: cover;
  width: 33px;
  height: 17px;
  position: relative;
}

.index .saly-36 {
  position: absolute !important;
  top: 43px !important;
  left: 445px !important;
}

.index .hero-text-lower {
  background-color: #7086ff;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 23px;
  width: 100%;
  height: 102px;
  padding: 19px 0 10px;
  display: flex;
  position: relative;
}

.index .decrease-your-time-wrapper {
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 30px;
  display: flex;
  position: relative;
}

.index .decrease-your-time {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  flex: 1;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-6 {
  color: #fff;
}

.index .text-wrapper-7 {
  color: #ffaef2;
  text-decoration: underline;
}

.index .frame-4 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 73px;
  padding: 0 0 7px;
  display: flex;
  position: relative;
}

.index .frame-wrapper {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 28px;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.index .frame-5 {
  object-fit: cover;
  align-self: stretch;
  width: 308px;
  position: relative;
}

.index .div-wrapper {
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: flex-end;
  gap: 25px;
  width: 100%;
  padding: 0 27px;
  display: flex;
  position: relative;
}

.index .text-wrapper-8 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 13px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .page-2 {
  background-color: #fff;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 7px;
  width: 100%;
  height: 1563px;
  display: flex;
  position: relative;
}

.index .frame-6 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 34px;
  width: 100%;
  height: 365px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
}

.index .frame-7 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: -25px;
  padding: 42px 25px 0;
  display: flex;
  position: relative;
}

.index .text-wrapper-9 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  flex: 1;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 29px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .connect-any-repo-and-wrapper {
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  margin-bottom: -25px;
  padding: 16px 30px 0;
  display: flex;
  position: relative;
}

.index .div-2 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  flex: 1;
  margin-top: -1px;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-10 {
  color: #000;
  letter-spacing: 0;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 18px;
  font-weight: 400;
}

.index .cta-2 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 19px;
  width: 100%;
  padding: 47px 49px 29px;
  display: flex;
  position: relative;
}

.index .text-wrapper-11 {
  color: #4d4d4d;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .overlap-group-wrapper {
  width: 221px;
  height: 35px;
  position: relative;
}

.index .overlap-group-2 {
  border-radius: 5px;
  width: 219px;
  height: 35px;
  position: relative;
}

.index .rectangle-2 {
  background-color: #7086ff;
  border-radius: 5px;
  width: 219px;
  height: 35px;
  transition: all .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.index .critique-parent {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 14px;
  width: 100%;
  height: 420px;
  display: flex;
  position: relative;
}

.index .img-wrapper {
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 174px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
}

.index .saly-2 {
  object-fit: cover;
  width: 160px;
  height: 195px;
  margin-top: -41px;
  position: relative;
}

.index .frame-8 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 17px;
  width: 100%;
  margin-bottom: -56px;
  padding: 0 30px;
  display: flex;
  position: relative;
}

.index .text-wrapper-12 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-13 {
  color: #6c6c6c;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .frame-9 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 5px;
  width: 100%;
  padding: 0 0 0 10px;
  display: flex;
  position: relative;
}

.index .vector {
  width: 10.94px;
  height: 11.71px;
  margin-left: -1.47px;
  position: relative;
}

.index .text-wrapper-14 {
  color: #000;
  letter-spacing: 0;
  flex: 1;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .critique-parent-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 14px;
  width: 100%;
  padding: 65px 25px 15px;
  display: flex;
  position: relative;
}

.index .saly-3 {
  object-fit: cover;
  width: 110px;
  height: 174px;
  margin-top: -20px;
  position: relative;
}

.index .frame-10 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 13px;
  width: 100%;
  padding: 0 30px;
  display: flex;
  position: relative;
}

.index .text-wrapper-15 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-16 {
  color: #6c6c6c;
  letter-spacing: 0;
  text-align: center;
  width: 315px;
  margin-left: -48.5px;
  margin-right: -48.5px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .frame-11 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 5px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .page-3 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 2653px;
  padding: 0 0 32px;
  display: flex;
  position: relative;
}

.index .frame-12 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 601px;
  padding: 15px 30px 0;
  display: flex;
  position: relative;
}

.index .deploy-your-code {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 29px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-17 {
  color: #ffaef2;
}

.index .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  flex: 1;
  margin-top: -1px;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.index .cta-3 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 19px;
  padding: 12px 0 29px;
  display: inline-flex;
  position: relative;
}

.index .text-wrapper-19 {
  color: #e9ecff;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .overlap-group-3 {
  background-color: #fff;
  border-radius: 5px;
  width: 219px;
  height: 35px;
  position: relative;
}

.index .explain-your-cta-2 {
  color: #7086ff;
  letter-spacing: 0;
  text-align: center;
  width: 189px;
  height: 19px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 7px;
  left: 16px;
}

.index .text-wrapper-20 {
  color: #e9ecff;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .frame-13 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 1926px;
  display: flex;
  position: relative;
}

.index .frame-14 {
  background-color: #fff;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 645px;
  padding: 16px 0 0;
  display: flex;
  position: relative;
}

.index .object-other-wrapper {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 196px;
  padding: 20px 30px;
  display: flex;
  position: relative;
}

.index .object-other-2 {
  object-fit: cover;
  width: 195px;
  height: 195px;
  margin-top: -19.5px;
  margin-bottom: -19.5px;
  position: relative;
}

.index .frame-15 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 4px;
  width: 100%;
  padding: 0 30px 30px;
  display: flex;
  position: relative;
}

.index .text-wrapper-21 {
  color: #7086ff;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 23px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-22 {
  color: #000;
  letter-spacing: 0;
  align-self: stretch;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 27px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-23 {
  color: #656565;
  letter-spacing: 0;
  align-self: stretch;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 21px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.index .do-you-really-mean {
  color: #000;
  letter-spacing: 0;
  align-self: stretch;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-24 {
  font-weight: 700;
}

.index .text-wrapper-25 {
  font-family: Product Sans-BoldItalic, Helvetica;
  font-style: italic;
  font-weight: 700;
}

.index .text-wrapper-26 {
  color: #656565;
  letter-spacing: 0;
  align-self: stretch;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 23px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .frame-16 {
  background-color: #fff;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-end;
  width: 100%;
  height: 720px;
  display: flex;
  position: relative;
}

.index .frame-17 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-end;
  width: 100%;
  padding: 0 30px;
  display: flex;
  position: relative;
}

.index .object-other-3 {
  object-fit: cover;
  width: 250px;
  height: 239px;
  position: relative;
}

.index .frame-18 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 492px;
  margin-bottom: -11px;
  padding: 0 30px 30px;
  display: flex;
  position: relative;
}

.index .text-wrapper-27 {
  color: #7086ff;
  letter-spacing: 0;
  text-align: right;
  align-self: stretch;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 23px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .we-ll-automatically {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  align-self: stretch;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 27px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-28 {
  color: #656565;
  letter-spacing: 0;
  text-align: right;
  align-self: stretch;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 21px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-29 {
  color: #656565;
  letter-spacing: 0;
  text-align: right;
  align-self: stretch;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 21px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .frame-19 {
  background-color: #fff;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 687px;
  margin-bottom: -126px;
  display: flex;
  position: relative;
}

.index .frame-20 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 0 30px;
  display: flex;
  position: relative;
}

.index .object-other-4 {
  object-fit: cover;
  width: 253px;
  height: 253px;
  position: relative;
}

.index .page-4 {
  background-color: #7086ff;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  width: 328px;
  height: 1353px;
  padding: 27px 0 0;
  display: flex;
  position: relative;
}

.index .frame-21 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 15px;
  width: 100%;
  padding: 0 30px;
  display: flex;
  position: relative;
}

.index .everyone-that-rides {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 27px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-30 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.index .frame-22 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 13px;
  width: 100%;
  padding: 0 30px;
  display: flex;
  position: relative;
}

.index .bottomnew-wrapper {
  flex: none;
  justify-content: flex-end;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.index .bottomnew {
  width: 210px;
  height: 270.75px;
  position: relative;
}

.index .wave-lets-us-deploy {
  color: #f2f4ff;
  letter-spacing: 0;
  text-align: right;
  align-self: stretch;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 26px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .frame-23 {
  flex: none;
  align-items: center;
  gap: 9px;
  display: inline-flex;
  position: relative;
}

.index .rectangle-3 {
  width: 68px;
  height: 69px;
  position: relative;
}

.index .frame-24 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.index .text-wrapper-31 {
  color: #f3f4ff;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-32 {
  color: #dee1ff;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .cards {
  object-fit: cover;
  width: 177px;
  height: 325px;
  position: relative;
}

.index .text-wrapper-33 {
  color: #f3f4ff;
  letter-spacing: 0;
  align-self: stretch;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 26px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .frame-25 {
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 9px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .group-2 {
  object-fit: cover;
  width: 71px;
  height: 75px;
  margin-left: -24px;
  position: relative;
}

.index .text-wrapper-34 {
  color: #dfe2ff;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .page-5 {
  background-color: #f1f4f9;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 754px;
  margin-bottom: -53px;
  padding: 65px 0 70px;
  display: flex;
  position: relative;
}

.index .frame-26 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 14px;
  width: 100%;
  margin-top: -54.5px;
  margin-bottom: -54.5px;
  display: flex;
  position: relative;
}

.index .saly-19-wrapper {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 30px 30px;
  display: flex;
  position: relative;
}

.index .saly-19 {
  background-image: url("1-1.48354e70.png") !important;
  width: 324px !important;
  height: 325px !important;
  margin-left: -28px !important;
  margin-right: -28px !important;
}

.index .frame-27 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 0 30px 70px;
  display: flex;
  position: relative;
}

.index .text-wrapper-35 {
  color: #000;
  letter-spacing: 0;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 29px;
  font-weight: 700;
}

.index .navigation-left-2 {
  background-color: #6f86ff;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 138px;
  padding: 0 51px;
  display: flex;
  position: relative;
}

.index .logo-wrapper {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 297px;
  display: flex;
  position: relative;
}

.index .logo-2 {
  object-fit: cover;
  width: 267px;
  height: 57px;
  position: relative;
}

.index .navigation-right-2 {
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 39px;
  display: flex;
  position: relative;
}

.index .text-wrapper-36 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.index .main-cta-2 {
  width: 144px;
  height: 51px;
  transition: all .2s;
  position: relative;
}

.index .main-cta-2:hover {
  transform: scale(1.11);
}

.index .group-3 {
  width: 146px;
  height: 51px;
  transition: all .2s;
}

.index .overlap-group-4 {
  background-color: #fff;
  border-radius: 10px;
  width: 144px;
  height: 51px;
  position: relative;
}

.index .text-wrapper-37 {
  color: #7086ff;
  letter-spacing: 0;
  text-align: center;
  width: 144px;
  height: 24px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 13px;
  left: 0;
}

.index .page-6 {
  background: linear-gradient(#fff 0%);
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 814px;
  padding: 47px 0 0;
  display: flex;
  position: relative;
}

.index .frame-28 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 14px;
  width: 100%;
  padding: 0 49px 46px 0;
  display: flex;
  position: relative;
}

.index .frame-29 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0 0 0 58px;
  display: flex;
  position: relative;
}

.index .text-wrapper-38 {
  color: #000;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 57px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-frame-2 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .deploy-any-backend-2 {
  color: #000;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 25px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-39 {
  color: #000;
  letter-spacing: 0;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 25px;
  font-weight: 700;
}

.index .cta-4 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding: 51px 110px 0 0;
  display: flex;
  position: relative;
}

.index .explained-cta-2 {
  width: 156px;
  height: 51px;
  transition: all .2s;
  position: relative;
}

.index .explained-cta-2:hover {
  transform: scale(1.1);
}

.index .overlap-group-5 {
  background-color: #7086ff;
  border-radius: 10px;
  width: 154px;
  height: 51px;
  position: relative;
}

.index .explain-your-cta-3 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 154px;
  height: 29px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 0;
}

.index .text-wrapper-40 {
  color: #656565;
  letter-spacing: 0;
  width: 453px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .group-4 {
  object-fit: cover;
  width: 408px;
  height: 326px;
  position: relative;
}

.index .using-the-frameworks {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  width: 681px;
  margin-top: -1px;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.index .banner-4 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 114px;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.index .r-2 {
  width: 114px;
  height: 46px;
  position: relative;
}

.index .asap-net-logo-2 {
  object-fit: cover;
  width: 114px;
  height: 46px;
  position: relative;
}

.index .r-3 {
  object-fit: cover;
  width: 150px;
  height: 46px;
  position: relative;
}

.index .element-flask-2 {
  object-fit: cover;
  width: 140px;
  height: 46px;
  position: relative;
}

.index .OIP-2 {
  object-fit: cover;
  width: 238px;
  height: 65px;
  position: relative;
}

.index .img-4 {
  object-fit: cover;
  width: 114px;
  height: 58px;
  position: relative;
}

.index .img-5 {
  object-fit: cover;
  width: 113px;
  height: 58px;
  position: relative;
}

.index .OIP-3 {
  object-fit: cover;
  width: 115px;
  height: 58px;
  position: relative;
}

.index .hero-text-lower-2 {
  background-color: #7086ff;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 506px;
  padding: 0 0 10px;
  display: flex;
  position: relative;
}

.index .frame-30 {
  flex: none;
  justify-content: center;
  align-items: center;
  width: 1107px;
  padding: 57px 116px;
  display: flex;
  position: relative;
}

.index .decrease-your-time-2 {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  flex: 1;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-41 {
  color: #fff;
  text-decoration: underline;
}

.index .frame-31 {
  width: 246px;
  height: 206px;
  position: relative;
}

.index .saly-4 {
  object-fit: cover;
  width: 629px;
  height: 451px;
  position: absolute;
  top: 0;
  left: 478px;
}

.index .frame-32 {
  flex: none;
  width: 914px;
  position: relative;
}

.index .frame-33 {
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: flex-end;
  gap: 25px;
  width: 100%;
  padding: 0 27px 10px;
  display: flex;
  position: relative;
}

.index .text-wrapper-42 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .page-7 {
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 3083px;
  padding: 110px 0 32px;
  display: flex;
  position: relative;
}

.index .frame-34 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 23px;
  width: 100%;
  margin-top: -8px;
  padding: 33px 115px 42px;
  display: flex;
  position: relative;
}

.index .text-wrapper-43 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 57px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .connect-any-repo-and {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  flex: 1;
  margin-top: -1px;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-44 {
  color: #000;
  letter-spacing: 0;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 20px;
  font-weight: 400;
}

.index .frame-35 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 14px;
  width: 100%;
  padding: 0 272px;
  display: flex;
  position: relative;
}

.index .text-wrapper-45 {
  color: #4d4d4d;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 25px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .explained-cta-3 {
  width: 156px;
  height: 51px;
  position: relative;
}

.index .text-wrapper-46 {
  color: #656565;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .critique-parent-3 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 14px;
  width: 100%;
  height: 383px;
  padding: 0 0 0 50px;
  display: flex;
  position: relative;
}

.index .frame-36 {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 293px;
  height: 369px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
}

.index .saly-5 {
  object-fit: cover;
  flex: 1;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.index .frame-37 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 0 30px;
  display: flex;
  position: relative;
}

.index .text-wrapper-47 {
  color: #000;
  letter-spacing: 0;
  align-self: stretch;
  height: 97px;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-48 {
  color: #6c6c6c;
  letter-spacing: 0;
  align-self: stretch;
  height: 97px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .frame-38 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 5px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .text-wrapper-49 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .critique-parent-4 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 14px;
  width: 100%;
  height: 382px;
  padding: 0 52px 0 50px;
  display: flex;
  position: relative;
}

.index .frame-39 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 6px;
  height: 260px;
  padding: 0 30px;
  display: flex;
  position: relative;
}

.index .text-wrapper-50 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  flex: 1;
  align-self: stretch;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-51 {
  color: #6c6c6c;
  letter-spacing: 0;
  text-align: right;
  flex: 1;
  align-self: stretch;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .frame-40 {
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 5px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .vector-2 {
  width: 10.94px;
  height: 11.71px;
  position: relative;
}

.index .frame-41 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 363px;
  height: 279px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
}

.index .saly-6 {
  object-fit: cover;
  width: 304px;
  height: 403px;
  margin-top: -72px;
  margin-bottom: -72px;
  position: relative;
}

.index .page-8 {
  background-color: #7086ff;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 655px;
  padding: 51px 0 0;
  display: flex;
  position: relative;
}

.index .frame-42 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 21px;
  width: 100%;
  height: 604px;
  padding: 15px 0;
  display: flex;
  position: relative;
}

.index .deploy-your-code-wrapper {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 38px;
  width: 100%;
  padding: 0 30px;
  display: flex;
  position: relative;
}

.index .deploy-your-code-2 {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  flex: 1;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 57px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-frame-3 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 28px;
  width: 100%;
  padding: 0 30px 34px;
  display: flex;
  position: relative;
}

.index .text-wrapper-52 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  flex: 1;
  margin-top: -1px;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-53 {
  color: #e9ecff;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 25px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .overlap-group-6 {
  background-color: #fff;
  border-radius: 10px;
  width: 154px;
  height: 51px;
  position: relative;
}

.index .explain-your-cta-4 {
  color: #7086ff;
  letter-spacing: 0;
  text-align: center;
  width: 154px;
  height: 29px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 0;
}

.index .text-wrapper-54 {
  color: #d5dbff;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .frame-43 {
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 353.33px;
  display: flex;
  position: relative;
}

.index .frame-44 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: flex-end;
  padding: 20px 23px;
  display: flex;
  position: relative;
}

.index .object-other-5 {
  object-fit: cover;
  width: 391px;
  height: 391px;
  margin-top: -38.83px;
  margin-bottom: -38.83px;
  position: relative;
}

.index .frame-45 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 4px;
  padding: 35px 30px 30px;
  display: inline-flex;
  position: relative;
}

.index .text-wrapper-55 {
  color: #7086ff;
  letter-spacing: 0;
  width: 468px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 23px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-56 {
  color: #000;
  letter-spacing: 0;
  width: 468px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 23px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-57 {
  color: #656565;
  letter-spacing: 0;
  width: 468px;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.index .do-you-really-mean-2 {
  color: #000;
  letter-spacing: 0;
  width: 468px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-58 {
  color: #656565;
  letter-spacing: 0;
  width: 468px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 23px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .frame-46 {
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 353.33px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.index .text-wrapper-59 {
  color: #7086ff;
  letter-spacing: 0;
  width: 579px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 23px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .we-ll-automatically-2 {
  color: #000;
  letter-spacing: 0;
  width: 579px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 23px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-60 {
  color: #656565;
  letter-spacing: 0;
  width: 579px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 23px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .object-other-01-wrapper {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  padding: 20px 23px;
  display: flex;
  position: relative;
}

.index .object-other-01 {
  background-image: url("object-other-01.333d5acb.png") !important;
  margin-top: -44.33px !important;
  margin-bottom: -44.33px !important;
  margin-right: -20px !important;
}

.index .page-9 {
  background-color: #7086ff;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 1035px;
  padding: 44px 0 0;
  display: flex;
  position: relative;
}

.index .everyone-that-rides-2 {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 57px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .frame-47 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 0 81px;
  display: flex;
  position: relative;
}

.index .text-wrapper-61 {
  color: #e9ecff;
  letter-spacing: 0;
  text-align: center;
  flex: 1;
  margin-top: -1px;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.index .frame-48 {
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 13px;
  width: 100%;
  padding: 27px 30px 20px 59px;
  display: flex;
  position: relative;
}

.index .frame-49 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 13px;
  padding: 0 0 0 23px;
  display: flex;
  position: relative;
}

.index .wave-lets-us-deploy-2 {
  color: #f2f4ff;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .rectangle-4 {
  width: 105px;
  height: 107px;
  position: relative;
}

.index .frame-50 {
  border: 0;
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 13px;
  width: 100%;
  padding: 27px 30px 20px;
  display: flex;
  position: relative;
}

.index .frame-51 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 13px;
  padding: 0 23px;
  display: flex;
  position: relative;
}

.index .text-wrapper-62 {
  color: #f3f4ff;
  letter-spacing: 0;
  text-align: right;
  align-self: stretch;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .group-5 {
  object-fit: cover;
  width: 112px;
  height: 118.33px;
  position: relative;
}

.index .cards-2 {
  object-fit: cover;
  width: 160px;
  height: 320px;
  margin-top: -1px;
  margin-bottom: -1px;
  position: relative;
}

.index .page-10 {
  background-color: #fff;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 801px;
  margin-bottom: -27px;
  display: flex;
  position: relative;
}

.index .frame-52 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 14px;
  width: 100%;
  height: 707px;
  display: flex;
  position: relative;
}

.index .saly-instance-wrapper {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  margin-top: -54.5px;
  padding: 0 30px;
  display: flex;
  position: relative;
}

.index .saly-instance {
  background-image: url("1-1.48354e70.png") !important;
}

.index .frame-53 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 22px;
  width: 100%;
  height: 358px;
  margin-bottom: -54.5px;
  padding: 0 30px;
  display: flex;
  position: relative;
}

.index .deploy-any-backend-3 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 57px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-63 {
  color: #000;
  letter-spacing: 0;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 57px;
  font-weight: 700;
}

.index .text-wrapper-64 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  flex: 1;
  margin-top: -1px;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.index .button {
  all: unset;
  box-sizing: border-box;
  width: 156px;
  height: 51px;
  position: relative;
}

.index .overlap-group-7 {
  all: unset;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  width: 154px;
  height: 51px;
  position: relative;
}

.index .explain-your-cta-5 {
  all: unset;
  box-sizing: border-box;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 154px;
  height: 29px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 0;
}

.index .frame-54 {
  background-color: #000;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 73px;
  padding: 10px;
  display: flex;
  position: relative;
}

.index .text-wrapper-65 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  height: 24px;
  font-family: Product Sans-Bold, Helvetica;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

/*# sourceMappingURL=index.2b31892c.css.map */
