.index {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.index .navigation-left {
  align-items: center;
  align-self: stretch;
  background-color: #7086ff;
  display: flex;
  height: 38px;
  padding: 0px 20px;
  position: relative;
  width: 100%;
}

.index .logo {
  height: 19px;
  object-fit: cover;
  position: relative;
  width: 90px;
}

.index .navigation-right {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 18px;
  height: 32px;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
}

.index .text-wrapper {
  color: #ffffff;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.index .main-cta {
  height: 23.25px;
  position: relative;
  transition: all 0.2s ease;
  width: 78px;
}

.index .overlap-group {
  height: 23px;
  position: relative;
  width: 76px;
  transition: transform 0.3s ease;
}

.index .overlap-group:hover, .index .overlap-group:active {
  transform: scale(1.1);
}

.index .rectangle {
  background-color: #ffffff;
  border-radius: 5px;
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 76px;
}

.index .div {
  color: #7086ff;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 11px;
  font-weight: 400;
  height: 23px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 5;
  width: 76px;
}

.index .page {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 692px;
  padding: 44px 0px 0px;
  position: relative;
  width: 100%;
}

.index .frame {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.index .frame-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 11px;
  justify-content: center;
  padding: 0px 30px;
  position: relative;
  width: 100%;
}

.index .p {
  align-self: stretch;
  color: #000000;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 29px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.index .text-frame {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 0px 21px;
  position: relative;
  width: 100%;
}

.index .deploy-any-backend {
  color: #000000;
  flex: 1;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.index .span {
  color: #000000;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
}

.index .text-wrapper-2 {
  text-decoration: underline;
}

.index .cta {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  padding: 25px 0px 42px;
  position: relative;
}

.index .explained-cta {
  height: 35px;
  margin-right: -2px;
  position: relative;
  transition: all 0.2s ease;
  width: 221px;
}

.index .explained-cta:hover {
  transform: scale(1.1);
}

.index .explain-your-cta-wrapper {
  background-color: #7086ff;
  border-radius: 5px;
  height: 35px;
  position: relative;
  width: 219px;
}

.index .explain-your-cta {
  color: #ffffff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 19px;
  left: 16px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 7px;
  width: 189px;
}

.index .text-wrapper-3 {
  align-self: stretch;
  color: #656565;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.index .group-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 256px;
  justify-content: center;
  margin-left: -39.5px;
  margin-right: -39.5px;
  padding: 0px 30px 2px;
  position: relative;
  width: 407px;
}

.index .group {
  height: 204px;
  object-fit: cover;
  position: relative;
  width: 226px;
}

.index .frame-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 129px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.index .banner {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.index .using-your {
  color: transparent;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.index .text-wrapper-4 {
  color: #000000;
}

.index .text-wrapper-5 {
  color: #ffaef2;
  font-family: "Product Sans-Bold", Helvetica;
  font-weight: 700;
}

.index .banner-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 55px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.index .r {
  height: 14px;
  position: relative;
  width: 34px;
}

.index .asap-net-logo {
  height: 14px;
  object-fit: cover;
  position: relative;
  width: 33px;
}

.index .img {
  height: 14px;
  object-fit: cover;
  position: relative;
  width: 44px;
}

.index .element-flask {
  height: 14px;
  object-fit: cover;
  position: relative;
  width: 41px;
}

.index .OIP {
  height: 19px;
  object-fit: cover;
  position: relative;
  width: 70px;
}

.index .img-2 {
  height: 17px;
  object-fit: cover;
  position: relative;
  width: 34px;
}

.index .banner-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 56px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.index .img-3 {
  height: 17px;
  object-fit: cover;
  position: relative;
  width: 33px;
}

.index .saly-36 {
  left: 445px !important;
  position: absolute !important;
  top: 43px !important;
}

.index .hero-text-lower {
  align-items: center;
  align-self: stretch;
  background-color: #7086ff;
  display: flex;
  flex-direction: column;
  gap: 23px;
  height: 102px;
  padding: 19px 0px 10px;
  position: relative;
  width: 100%;
}

.index .decrease-your-time-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  padding: 0px 30px;
  position: relative;
  width: 100%;
}

.index .decrease-your-time {
  color: transparent;
  flex: 1;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.index .text-wrapper-6 {
  color: #ffffff;
}

.index .text-wrapper-7 {
  color: #ffaef2;
  text-decoration: underline;
}

.index .frame-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 73px;
  padding: 0px 0px 7px;
  position: relative;
  width: 100%;
}

.index .frame-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 28px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.index .frame-5 {
  align-self: stretch;
  object-fit: cover;
  position: relative;
  width: 308px;
}

.index .div-wrapper {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 25px;
  justify-content: center;
  padding: 0px 27px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-8 {
  color: #ffffff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.index .page-2 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 7px;
  height: 1563px;
  position: relative;
  width: 100%;
}

.index .frame-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 34px;
  height: 365px;
  justify-content: center;
  padding: 20px 0px 0px;
  position: relative;
  width: 100%;
}

.index .frame-7 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  margin-top: -25px;
  padding: 42px 25px 0px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-9 {
  color: #000000;
  flex: 1;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 29px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.index .connect-any-repo-and-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  margin-bottom: -25px;
  padding: 16px 30px 0px;
  position: relative;
  width: 100%;
}

.index .div-2 {
  color: #000000;
  flex: 1;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.index .text-wrapper-10 {
  color: #000000;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
}

.index .cta-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 19px;
  justify-content: center;
  padding: 47px 49px 29px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-11 {
  align-self: stretch;
  color: #4d4d4d;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.index .overlap-group-wrapper {
  height: 35px;
  position: relative;
  width: 221px;
}

.index .overlap-group-2 {
  border-radius: 5px;
  height: 35px;
  position: relative;
  width: 219px;
}

.index .rectangle-2 {
  background-color: #7086ff;
  border-radius: 5px;
  height: 35px;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s ease;
  width: 219px;
}

.index .critique-parent {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 14px;
  height: 420px;
  position: relative;
  width: 100%;
}

.index .img-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 174px;
  justify-content: flex-end;
  padding: 20px 0px 0px;
  position: relative;
  width: 100%;
}

.index .saly-2 {
  height: 195px;
  margin-top: -41px;
  object-fit: cover;
  position: relative;
  width: 160px;
}

.index .frame-8 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 17px;
  margin-bottom: -56px;
  padding: 0px 30px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-12 {
  align-self: stretch;
  color: #000000;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.index .text-wrapper-13 {
  align-self: stretch;
  color: #6c6c6c;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.index .frame-9 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 5px;
  justify-content: center;
  padding: 0px 0px 0px 10px;
  position: relative;
  width: 100%;
}

.index .vector {
  height: 11.71px;
  margin-left: -1.47px;
  position: relative;
  width: 10.94px;
}

.index .text-wrapper-14 {
  color: #000000;
  flex: 1;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.index .critique-parent-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  padding: 65px 25px 15px;
  position: relative;
  width: 100%;
}

.index .saly-3 {
  height: 174px;
  margin-top: -20px;
  object-fit: cover;
  position: relative;
  width: 110px;
}

.index .frame-10 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13px;
  padding: 0px 30px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-15 {
  align-self: stretch;
  color: #000000;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.index .text-wrapper-16 {
  color: #6c6c6c;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -48.5px;
  margin-right: -48.5px;
  position: relative;
  text-align: center;
  width: 315px;
}

.index .frame-11 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 5px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.index .page-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 2653px;
  padding: 0px 0px 32px;
  position: relative;
  width: 100%;
}

.index .frame-12 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 601px;
  justify-content: center;
  padding: 15px 30px 0px;
  position: relative;
  width: 100%;
}

.index .deploy-your-code {
  align-self: stretch;
  color: transparent;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 29px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.index .text-wrapper-17 {
  color: #ffaef2;
}

.index .text-wrapper-18 {
  color: #ffffff;
  flex: 1;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.index .cta-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 19px;
  justify-content: center;
  padding: 12px 0px 29px;
  position: relative;
}

.index .text-wrapper-19 {
  align-self: stretch;
  color: #e9ecff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.index .overlap-group-3 {
  background-color: #ffffff;
  border-radius: 5px;
  height: 35px;
  position: relative;
  width: 219px;
}

.index .explain-your-cta-2 {
  color: #7086ff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 19px;
  left: 16px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 7px;
  width: 189px;
}

.index .text-wrapper-20 {
  align-self: stretch;
  color: #e9ecff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.index .frame-13 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 1926px;
  position: relative;
  width: 100%;
}

.index .frame-14 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 645px;
  padding: 16px 0px 0px;
  position: relative;
  width: 100%;
}

.index .object-other-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 196px;
  justify-content: center;
  padding: 20px 30px;
  position: relative;
  width: 100%;
}

.index .object-other-2 {
  height: 195px;
  margin-bottom: -19.5px;
  margin-top: -19.5px;
  object-fit: cover;
  position: relative;
  width: 195px;
}

.index .frame-15 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  padding: 0px 30px 30px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-21 {
  align-self: stretch;
  color: #7086ff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.index .text-wrapper-22 {
  align-self: stretch;
  color: #000000;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 27px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-23 {
  align-self: stretch;
  color: #656565;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 21px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.index .do-you-really-mean {
  align-self: stretch;
  color: #000000;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-24 {
  font-weight: 700;
}

.index .text-wrapper-25 {
  font-family: "Product Sans-BoldItalic", Helvetica;
  font-style: italic;
  font-weight: 700;
}

.index .text-wrapper-26 {
  align-self: stretch;
  color: #656565;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.index .frame-16 {
  align-items: flex-end;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 720px;
  position: relative;
  width: 100%;
}

.index .frame-17 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 0px 30px;
  position: relative;
  width: 100%;
}

.index .object-other-3 {
  height: 239px;
  object-fit: cover;
  position: relative;
  width: 250px;
}

.index .frame-18 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 492px;
  justify-content: center;
  margin-bottom: -11px;
  padding: 0px 30px 30px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-27 {
  align-self: stretch;
  color: #7086ff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: right;
}

.index .we-ll-automatically {
  align-self: stretch;
  color: #000000;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 27px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: right;
}

.index .text-wrapper-28 {
  align-self: stretch;
  color: #656565;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 21px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: right;
}

.index .text-wrapper-29 {
  align-self: stretch;
  color: #656565;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: right;
}

.index .frame-19 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 687px;
  margin-bottom: -126px;
  position: relative;
  width: 100%;
}

.index .frame-20 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 0px 30px;
  position: relative;
  width: 100%;
}

.index .object-other-4 {
  height: 253px;
  object-fit: cover;
  position: relative;
  width: 253px;
}

.index .page-4 {
  align-items: center;
  background-color: #7086ff;
  display: flex;
  flex-direction: column;
  gap: 28px;
  height: 1353px;
  padding: 27px 0px 0px;
  position: relative;
  width: 328px;
}

.index .frame-21 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  padding: 0px 30px;
  position: relative;
  width: 100%;
}

.index .everyone-that-rides {
  align-self: stretch;
  color: transparent;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 27px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.index .text-wrapper-30 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.index .frame-22 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13px;
  justify-content: center;
  padding: 0px 30px;
  position: relative;
  width: 100%;
}

.index .bottomnew-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  position: relative;
}

.index .bottomnew {
  height: 270.75px;
  position: relative;
  width: 210px;
}

.index .wave-lets-us-deploy {
  align-self: stretch;
  color: #f2f4ff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: right;
}

.index .frame-23 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 9px;
  position: relative;
}

.index .rectangle-3 {
  height: 69px;
  position: relative;
  width: 68px;
}

.index .frame-24 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  position: relative;
}

.index .text-wrapper-31 {
  color: #f3f4ff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.index .text-wrapper-32 {
  color: #dee1ff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.index .cards {
  height: 325px;
  object-fit: cover;
  position: relative;
  width: 177px;
}

.index .text-wrapper-33 {
  align-self: stretch;
  color: #f3f4ff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.index .frame-25 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 9px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.index .group-2 {
  height: 75px;
  margin-left: -24px;
  object-fit: cover;
  position: relative;
  width: 71px;
}

.index .text-wrapper-34 {
  color: #dfe2ff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.index .page-5 {
  align-items: center;
  align-self: stretch;
  background-color: #f1f4f9;
  display: flex;
  flex-direction: column;
  height: 754px;
  justify-content: center;
  margin-bottom: -53px;
  padding: 65px 0px 70px;
  position: relative;
  width: 100%;
}

.index .frame-26 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  margin-bottom: -54.5px;
  margin-top: -54.5px;
  position: relative;
  width: 100%;
}

.index .saly-19-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 0px 30px 30px;
  position: relative;
  width: 100%;
}

.index .saly-19 {
  background-image: url(../../../static/img/1-1.png) !important;
  height: 325px !important;
  margin-left: -28px !important;
  margin-right: -28px !important;
  width: 324px !important;
}

.index .frame-27 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 0px 30px 70px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-35 {
  color: #000000;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 29px;
  font-weight: 700;
  letter-spacing: 0;
}

.index .navigation-left-2 {
  align-items: center;
  align-self: stretch;
  background-color: #6f86ff;
  display: flex;
  height: 138px;
  justify-content: center;
  padding: 0px 51px;
  position: relative;
  width: 100%;
}

.index .logo-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 297px;
}

.index .logo-2 {
  height: 57px;
  object-fit: cover;
  position: relative;
  width: 267px;
}

.index .navigation-right-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 39px;
  justify-content: flex-end;
  position: relative;
}

.index .text-wrapper-36 {
  color: #ffffff;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.index .main-cta-2 {
  height: 51px;
  position: relative;
  transition: all 0.2s ease;
  width: 144px;
}

.index .main-cta-2:hover {
  transform: scale(1.11);
}

.index .group-3 {
  height: 51px;
  transition: all 0.2s ease;
  width: 146px;
}

.index .overlap-group-4 {
  background-color: #ffffff;
  border-radius: 10px;
  height: 51px;
  position: relative;
  width: 144px;
}

.index .text-wrapper-37 {
  color: #7086ff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 24px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 13px;
  width: 144px;
}

.index .page-6 {
  align-items: center;
  align-self: stretch;
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%);
  display: flex;
  flex-direction: column;
  height: 814px;
  justify-content: center;
  padding: 47px 0px 0px;
  position: relative;
  width: 100%;
}

.index .frame-28 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 14px;
  padding: 0px 49px 46px 0px;
  position: relative;
  width: 100%;
}

.index .frame-29 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  justify-content: center;
  padding: 0px 0px 0px 58px;
  position: relative;
}

.index .text-wrapper-38 {
  align-self: stretch;
  color: #000000;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 57px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.index .text-frame-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.index .deploy-any-backend-2 {
  align-self: stretch;
  color: #000000;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.index .text-wrapper-39 {
  color: #000000;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 0;
}

.index .cta-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 51px 110px 0px 0px;
  position: relative;
  width: 100%;
}

.index .explained-cta-2 {
  height: 51px;
  position: relative;
  transition: all 0.2s ease;
  width: 156px;
}

.index .explained-cta-2:hover {
  transform: scale(1.1);
}

.index .overlap-group-5 {
  background-color: #7086ff;
  border-radius: 10px;
  height: 51px;
  position: relative;
  width: 154px;
}

.index .explain-your-cta-3 {
  color: #ffffff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 29px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 154px;
}

.index .text-wrapper-40 {
  color: #656565;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 453px;
}

.index .group-4 {
  height: 326px;
  object-fit: cover;
  position: relative;
  width: 408px;
}

.index .using-the-frameworks {
  color: transparent;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 681px;
}

.index .banner-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 114px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.index .r-2 {
  height: 46px;
  position: relative;
  width: 114px;
}

.index .asap-net-logo-2 {
  height: 46px;
  object-fit: cover;
  position: relative;
  width: 114px;
}

.index .r-3 {
  height: 46px;
  object-fit: cover;
  position: relative;
  width: 150px;
}

.index .element-flask-2 {
  height: 46px;
  object-fit: cover;
  position: relative;
  width: 140px;
}

.index .OIP-2 {
  height: 65px;
  object-fit: cover;
  position: relative;
  width: 238px;
}

.index .img-4 {
  height: 58px;
  object-fit: cover;
  position: relative;
  width: 114px;
}

.index .img-5 {
  height: 58px;
  object-fit: cover;
  position: relative;
  width: 113px;
}

.index .OIP-3 {
  height: 58px;
  object-fit: cover;
  position: relative;
  width: 115px;
}

.index .hero-text-lower-2 {
  align-items: center;
  align-self: stretch;
  background-color: #7086ff;
  display: flex;
  flex-direction: column;
  height: 506px;
  justify-content: center;
  padding: 0px 0px 10px;
  position: relative;
  width: 100%;
}

.index .frame-30 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 57px 116px;
  position: relative;
  width: 1107px;
}

.index .decrease-your-time-2 {
  color: transparent;
  flex: 1;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.index .text-wrapper-41 {
  color: #ffffff;
  text-decoration: underline;
}

.index .frame-31 {
  height: 206px;
  position: relative;
  width: 246px;
}

.index .saly-4 {
  height: 451px;
  left: 478px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 629px;
}

.index .frame-32 {
  flex: 0 0 auto;
  position: relative;
  width: 914px;
}

.index .frame-33 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 25px;
  justify-content: center;
  padding: 0px 27px 10px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-42 {
  color: #ffffff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.index .page-7 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 3083px;
  justify-content: flex-end;
  padding: 110px 0px 32px;
  position: relative;
  width: 100%;
}

.index .frame-34 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 23px;
  justify-content: center;
  margin-top: -8px;
  padding: 33px 115px 42px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-43 {
  align-self: stretch;
  color: #000000;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 57px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.index .connect-any-repo-and {
  color: #000000;
  flex: 1;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.index .text-wrapper-44 {
  color: #000000;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
}

.index .frame-35 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  padding: 0px 272px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-45 {
  align-self: stretch;
  color: #4d4d4d;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.index .explained-cta-3 {
  height: 51px;
  position: relative;
  width: 156px;
}

.index .text-wrapper-46 {
  color: #656565;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.index .critique-parent-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 14px;
  height: 383px;
  justify-content: center;
  padding: 0px 0px 0px 50px;
  position: relative;
  width: 100%;
}

.index .frame-36 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 369px;
  padding: 20px 0px 0px;
  position: relative;
  width: 293px;
}

.index .saly-5 {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.index .frame-37 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 6px;
  justify-content: center;
  padding: 0px 30px;
  position: relative;
}

.index .text-wrapper-47 {
  align-self: stretch;
  color: #000000;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 40px;
  font-weight: 700;
  height: 97px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.index .text-wrapper-48 {
  align-self: stretch;
  color: #6c6c6c;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 97px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.index .frame-38 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 5px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-49 {
  color: #000000;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.index .critique-parent-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 14px;
  height: 382px;
  justify-content: center;
  padding: 0px 52px 0px 50px;
  position: relative;
  width: 100%;
}

.index .frame-39 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 6px;
  height: 260px;
  justify-content: center;
  padding: 0px 30px;
  position: relative;
}

.index .text-wrapper-50 {
  align-self: stretch;
  color: #000000;
  flex: 1;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: right;
}

.index .text-wrapper-51 {
  align-self: stretch;
  color: #6c6c6c;
  flex: 1;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: right;
}

.index .frame-40 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 5px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.index .vector-2 {
  height: 11.71px;
  position: relative;
  width: 10.94px;
}

.index .frame-41 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 279px;
  justify-content: center;
  padding: 20px 0px 0px;
  position: relative;
  width: 363px;
}

.index .saly-6 {
  height: 403px;
  margin-bottom: -72px;
  margin-top: -72px;
  object-fit: cover;
  position: relative;
  width: 304px;
}

.index .page-8 {
  align-items: center;
  align-self: stretch;
  background-color: #7086ff;
  display: flex;
  flex-direction: column;
  height: 655px;
  padding: 51px 0px 0px;
  position: relative;
  width: 100%;
}

.index .frame-42 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 21px;
  height: 604px;
  justify-content: center;
  padding: 15px 0px;
  position: relative;
  width: 100%;
}

.index .deploy-your-code-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 38px;
  padding: 0px 30px;
  position: relative;
  width: 100%;
}

.index .deploy-your-code-2 {
  color: transparent;
  flex: 1;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 57px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.index .text-frame-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 28px;
  padding: 0px 30px 34px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-52 {
  color: #ffffff;
  flex: 1;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.index .text-wrapper-53 {
  align-self: stretch;
  color: #e9ecff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.index .overlap-group-6 {
  background-color: #ffffff;
  border-radius: 10px;
  height: 51px;
  position: relative;
  width: 154px;
}

.index .explain-your-cta-4 {
  color: #7086ff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 29px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 154px;
}

.index .text-wrapper-54 {
  color: #d5dbff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.index .frame-43 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 353.33px;
  position: relative;
  width: 100%;
}

.index .frame-44 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 20px 23px;
  position: relative;
}

.index .object-other-5 {
  height: 391px;
  margin-bottom: -38.83px;
  margin-top: -38.83px;
  object-fit: cover;
  position: relative;
  width: 391px;
}

.index .frame-45 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  padding: 35px 30px 30px;
  position: relative;
}

.index .text-wrapper-55 {
  color: #7086ff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 468px;
}

.index .text-wrapper-56 {
  color: #000000;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 468px;
}

.index .text-wrapper-57 {
  color: #656565;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 468px;
}

.index .do-you-really-mean-2 {
  color: #000000;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 468px;
}

.index .text-wrapper-58 {
  color: #656565;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 468px;
}

.index .frame-46 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 353.33px;
  padding: 0px 20px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-59 {
  color: #7086ff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 579px;
}

.index .we-ll-automatically-2 {
  color: #000000;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 579px;
}

.index .text-wrapper-60 {
  color: #656565;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 579px;
}

.index .object-other-01-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 20px 23px;
  position: relative;
}

.index .object-other-01 {
  background-image: url(../../../static/img/object-other-01.png) !important;
  margin-bottom: -44.33px !important;
  margin-right: -20px !important;
  margin-top: -44.33px !important;
}

.index .page-9 {
  align-items: center;
  align-self: stretch;
  background-color: #7086ff;
  display: flex;
  flex-direction: column;
  height: 1035px;
  justify-content: center;
  padding: 44px 0px 0px;
  position: relative;
  width: 100%;
}

.index .everyone-that-rides-2 {
  align-self: stretch;
  color: transparent;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 57px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.index .frame-47 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 0px 81px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-61 {
  color: #e9ecff;
  flex: 1;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.index .frame-48 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 13px;
  justify-content: flex-end;
  padding: 27px 30px 20px 59px;
  position: relative;
  width: 100%;
}

.index .frame-49 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 13px;
  justify-content: center;
  padding: 0px 0px 0px 23px;
  position: relative;
}

.index .wave-lets-us-deploy-2 {
  align-self: stretch;
  color: #f2f4ff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.index .rectangle-4 {
  height: 107px;
  position: relative;
  width: 105px;
}

.index .frame-50 {
  align-items: center;
  align-self: stretch;
  border: 0px none;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 13px;
  justify-content: flex-end;
  padding: 27px 30px 20px;
  position: relative;
  width: 100%;
}

.index .frame-51 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 13px;
  justify-content: center;
  padding: 0px 23px;
  position: relative;
}

.index .text-wrapper-62 {
  align-self: stretch;
  color: #f3f4ff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: right;
}

.index .group-5 {
  height: 118.33px;
  object-fit: cover;
  position: relative;
  width: 112px;
}

.index .cards-2 {
  height: 320px;
  margin-bottom: -1px;
  margin-top: -1px;
  object-fit: cover;
  position: relative;
  width: 160px;
}

.index .page-10 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 801px;
  margin-bottom: -27px;
  position: relative;
  width: 100%;
}

.index .frame-52 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 14px;
  height: 707px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.index .saly-instance-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  margin-top: -54.5px;
  padding: 0px 30px;
  position: relative;
  width: 100%;
}

.index .saly-instance {
  background-image: url(../../../static/img/1-1.png) !important;
}

.index .frame-53 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 22px;
  height: 358px;
  justify-content: center;
  margin-bottom: -54.5px;
  padding: 0px 30px;
  position: relative;
  width: 100%;
}

.index .deploy-any-backend-3 {
  align-self: stretch;
  color: #000000;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 57px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.index .text-wrapper-63 {
  color: #000000;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 57px;
  font-weight: 700;
  letter-spacing: 0;
}

.index .text-wrapper-64 {
  color: #000000;
  flex: 1;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.index .button {
  all: unset;
  box-sizing: border-box;
  height: 51px;
  position: relative;
  width: 156px;
}

.index .overlap-group-7 {
  all: unset;
  background-color: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  height: 51px;
  position: relative;
  width: 154px;
}

.index .explain-your-cta-5 {
  all: unset;
  box-sizing: border-box;
  color: #ffffff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 29px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 154px;
}

.index .frame-54 {
  align-items: center;
  align-self: stretch;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 73px;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-65 {
  color: #ffffff;
  font-family: "Product Sans-Bold", Helvetica;
  font-weight: 700;
  height: 24px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}
